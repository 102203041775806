import type { Ref } from 'vue'

const useStickyHelper = (threshold: number): { isScrolledAboveThreshold: Ref<boolean> } => {
  const isScrolledAboveThreshold = ref<boolean>(false)
  const setScrolledAboveThreshold = (): void => {
    isScrolledAboveThreshold.value = window.scrollY > threshold
  }

  onMounted((): void => {
    window.addEventListener('scroll', setScrolledAboveThreshold, false)
    setScrolledAboveThreshold()
  })
  onBeforeUnmount((): void => {
    window.removeEventListener('scroll', setScrolledAboveThreshold)
  })
  return {
    isScrolledAboveThreshold
  }
}
export {
  useStickyHelper
}
